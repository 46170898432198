import React from 'react'


export default function head() {
    return (
        <div className="App">
            <header className="App-header">
            
                <div className='tang'>
                
                    hi, I'm
                        
                        </div>
                <div>
                    <p className='type'>
                        <b>Vishal Kumar Prasad</b>
                        </p>
                        </div>
                    
                <div>
                    <p> <b>Data Engineer </b></p>
                    </div>
                
                
                <div><p>  </p></div>
                <div><p>  </p></div>
                
                <div>
                    <p> <b> PotterHead | Traveller | GadgetGeek | Inquisitive | Cinephile </b>
                        
                    </p>
                
                </div>
                <div><p>  </p></div>
                <div><p>  </p></div>
                <div><p>  </p></div>
                <div><p>  </p></div>
            </header>
            
        </div>
    )
}
